import React from "react";
import { RouteObject } from "react-router-dom";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { dailyProfilesRoute } from "./dailyProfiles";
import { heatmapPageRoute } from "./heatmap";
import { settingsPath } from "./settings";
import { timeSeriesRoute } from "./timeSeries";
import { weeklyProfilesRoute } from "./weeklyProfiles";

const Overview = lazyWithRetry(() => import("../pages/Overview/Overview"));

export const analysisRoute: RouteObject = {
  path: ":analysisId",
  children: [
    { index: true, element: <Overview /> },
    settingsPath,
    timeSeriesRoute,
    heatmapPageRoute,
    weeklyProfilesRoute,
    dailyProfilesRoute,
  ],
};
